.App {
  text-align: center;
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.App-main {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  color: #282c34;
}


.errors {
  display: flex;
  justify-content: center;
}

.loading {
  padding: 2rem;
}

.anchor {
  position: absolute;
  transform: translateY(-50vh);
}


.desc_stats_anchor {
  position: absolute;
}